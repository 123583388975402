import React, { useState } from "react";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { Grid } from "@material-ui/core/index";
import {
  formatWeight,
  getNestedObjValue,
  getProductImageUri,
  imageExpired,
  isAdmin,
  isVendor,
  isDivisionControlled,
  selectedDivisionId,
} from "@ui/Utils/helper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/index";
import { Typography } from "@material-ui/core/index";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import { Dialog } from "@material-ui/core/index";
import { MyDialogContent, MyDialogTitle } from "@ui/MuiComponents/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@material-ui/core/index";
import "./divisionAnimation.css";
import Label from "@ui/components/Label";
import enums from "helpers/enums";
import priceBreakUpIcon from "@ui/assets/e-creatives/orders/priceBreakup.svg";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";
import LazyLoad from "@ui/Actions/LazyLoad/index";
// import { useFormContext } from "react-hook-form";

const ProductItem = ({
  item,
  itemIndex,
  isEditable,
  vendorCodes = [],
  // customerId,
  // initialValues,
}) => {
  const sameDivision = isDivisionControlled
    ? selectedDivisionId === item?.product?.division?._id ||
      selectedDivisionId === item?.division?._id
    : true;

  isEditable = isDivisionControlled
    ? sameDivision
      ? isEditable
      : false
    : isEditable;

  // const formMethods = useFormContext();
  // const { setValue } = formMethods;
  // const productSearchUrl =
  //   enums.models.products + "/search?hook=true&priceAfterDiscount=true";
  // const lockedFilterCriteria = {
  //   $or: [
  //     {
  //       "lockedFrom.orderPro": { $exists: false },
  //     },
  //     { "lockedFrom.orderPro": customerId },
  //   ],
  // };

  const [openBreakup, setOpenBreakup] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  // const [issuedStocksOptions, setIssuedStocksOptions] = useState([]);
  const isCustomized =
    typeof item.isCustomized === "boolean" && item.isCustomized
      ? enums.customizationType.product
      : "";
  // const originalItem = initialValues?.items?.[itemIndex] || {};
  // // isRepair and isREturneed not used till now.. Aug 22, 2024...
  // const isDelivered =
  //     item.orderStatus === enums["vendor-portal"].orderStatus.delivered,
  //   isRepair = item.orderStatus === enums["vendor-portal"].orderStatus.repair,
  //   isReturned =
  //     item.orderStatus === enums["vendor-portal"].orderStatus.returned;

  const headingColor =
    isCustomized === enums.customizationType.product
      ? { backgroundColor: "black", color: "white" }
      : isCustomized === enums.customizationType.scratch
      ? { backgroundColor: "#a86d32", color: "white" }
      : { backgroundColor: "green", color: "white" };

  const [openImagePreview, setOpenImagePreview] = useState(false);
  const stockImageLocation =
    isCustomized === enums.customizationType.scratch
      ? item.images?.[0]?.signedUrl
      : getProductImageUri(item?.product || {});

  // const getWorkflowOptions = () => {
  //   return Object.values(enums.orderStatus).filter((oStatus) => {
  //     if (oStatus === enums.orderStatus.repair)
  //       return (
  //         originalItem.orderStatus === enums.orderStatus.delivered ||
  //         originalItem.orderStatus === enums.orderStatus.repair ||
  //         originalItem.orderStatus === enums.orderStatus.returned
  //       );
  //     else if (oStatus === enums.orderStatus.returned)
  //       return (
  //         originalItem.orderStatus === enums.orderStatus.delivered ||
  //         originalItem.orderStatus === enums.orderStatus.repair ||
  //         originalItem.orderStatus === enums.orderStatus.returned
  //       );

  //     switch (originalItem?.orderStatus) {
  //       case enums.orderStatus.inReview:
  //         return true;
  //       case enums.orderStatus.accepted:
  //         return oStatus !== enums.orderStatus.inReview;

  //       case enums.orderStatus.making:
  //         return !(
  //           oStatus === enums.orderStatus.accepted ||
  //           oStatus === enums.orderStatus.inReview
  //         );
  //       case enums.orderStatus.dispatched:
  //         return !(
  //           oStatus === enums.orderStatus.making ||
  //           oStatus === enums.orderStatus.accepted ||
  //           oStatus === enums.orderStatus.inReview
  //         );
  //       case enums.orderStatus.delivered:
  //         return (
  //           oStatus === enums.orderStatus.repair ||
  //           oStatus === enums.orderStatus.delivered ||
  //           oStatus === enums.orderStatus.returned
  //         );
  //       case enums.orderStatus.repair:
  //         return (
  //           oStatus === enums.orderStatus.repair ||
  //           oStatus === enums.orderStatus.delivered ||
  //           oStatus === enums.orderStatus.returned
  //         );
  //       case enums.orderStatus.returned:
  //       case enums.orderStatus.canceled:
  //         return true;
  //     }
  //   });
  // };

  return (
    <MainSection
      title={
        <Grid container spacing={2}>
          <Grid item xs={isCustomized ? 10 : 12}>
            <h3
              style={{
                ...headingColor,
                textAlign: "center",
                height: 50,
                paddingTop: 15,
              }}
            >
              {isCustomized
                ? `${
                    itemIndex + 1
                  }. Customized from ${isCustomized.toUpperCase()}`
                : `${itemIndex + 1}. ORIGINAL Product`}
            </h3>
          </Grid>
          {isCustomized ? (
            <Grid item xs={2}>
              <Tooltip title="View Customizations">
                <VisibilityIcon
                  onClick={() => setOpenDetails(true)}
                  style={{ fontSize: 50, cursor: "pointer", marginTop: "16px" }}
                />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      }
      titleStyle={{ color: "black" }}
      style={{ marginBottom: "24px", opacity: sameDivision ? 1 : 0.5 }}
      actions={[
        {
          buttonType: "custom",
          customIcon: (
            <div
              style={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: "12px",
                backgroundColor: sameDivision ? "black" : "grey",
                color: sameDivision ? "gold" : "white",
                fontSize: "14px",
                fontWeight: "bold",
                overflow: "hidden", // Hide overflowing animation effects
              }}
            >
              {sameDivision ? (
                <div
                  style={{
                    animation: "shine 1.5s infinite", // Animation for the shining effect
                  }}
                >
                  ⭐{" "}
                  {item?.product?.division?.name || item?.division?.name || ""}
                </div>
              ) : (
                <div>
                  {item?.product?.division?.name || item?.division?.name || ""}
                </div>
              )}
            </div>
          ),
        },
      ]}
    >
      <Grid container spacing={3} key={itemIndex} style={{ padding: "16px" }}>
        <Grid item xs={2}>
          <Label
            label={"SKU"}
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            value={item?.product?.sku || "-"}
          />
        </Grid>

        <Grid item xs={2}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Gross >< Net Weight"}
            value={`${
              item?.product?.grossWeight
                ? formatWeight(item?.product?.grossWeight)
                : "-"
            } >< ${
              item?.product?.netWeight
                ? formatWeight(item?.product?.netWeight)
                : ""
            }`}
          />
        </Grid>

        <Grid item xs={2}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Diamond Weight"}
            value={
              item?.product?.totalDiamondWeight
                ? formatWeight(item?.product?.totalDiamondWeight, {
                    inputInCts: true,
                  })
                : "-"
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Engraving"}
            value={`${item.product?.specificEngraving || "-"}`}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Category"}
            value={item?.product?.category?.name || "-"}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Purity"}
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            value={
              item?.product?.purity?.value
                ? item?.product?.purity?.value + " KT"
                : ""
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Color"}
            value={item?.product?.color || "-"}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Size"}
            value={item?.product?.size || "-"}
          />
        </Grid>

        {/* <Grid item xs={1}>
            {uiConsoleObject ? (
              <Tooltip
                placement="top"
                PopperProps={{
                  strategy: "fixed", // This will keep the Tooltip inside the viewport
                }}
                title={(() => {
                  return <pre>{JSON.stringify(uiConsoleObject, null, 2)}</pre>;
                })()}
              >
                <TroubleshootIcon style={{ fontSize: "48px", color: "gold" }} />
              </Tooltip>
            ) : null}
          </Grid> */}

        <Grid
          item
          xs={2}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenImagePreview(true)}
        >
          <LazyLoad>
            <img
              src={stockImageLocation}
              style={{ height: "10rem", width: "10rem", objectFit: "contain" }}
              onError={(event) => {
                event.target.src = imageExpired;
              }}
            />
          </LazyLoad>
          <pre style={{ fontSize: "12px", fontWeight: "bold" }}>
            View Details
          </pre>
        </Grid>

        {openImagePreview ? (
          <ProductDetailPopup
            open={openImagePreview}
            onClose={() => setOpenImagePreview()}
            product={item.product}
          />
        ) : null}

        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Label
                labelStyle={componentStyles.label}
                valueStyle={componentStyles.value}
                label={"Quantity"}
                value={`${item.quantity}`}
              />
            </Grid>

            <Grid item xs={5}>
              <Label
                labelStyle={componentStyles.label}
                valueStyle={componentStyles.value}
                label={"Remarks"}
                value={item?.remarks}
              />
            </Grid>
            <Grid item xs={2}>
              {isVendor ? null : (
                <Label
                  labelStyle={componentStyles.label}
                  valueStyle={componentStyles.value}
                  label={"Ordered Price"}
                  value={`${item.product?.jewelPrice || "-"}`}
                />
              )}
            </Grid>

            {/* <Grid item xs={4}></Grid> */}

            <Grid item xs={2}>
              {isVendor ? null : (
                <Tooltip title="Ordered Price Breakup">
                  <img
                    src={priceBreakUpIcon}
                    style={{ cursor: "pointer", height: "50px", width: "50px" }}
                    onClick={() => setOpenBreakup([item.product])} // sameDivision && put this in if you want to disable open price breakup for other division stocks which looks disabled
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          {isEditable && isAdmin ? (
            <RHAutoComplete
              options={vendorCodes}
              name={`items[${itemIndex}].vendorCode`}
              label={"Vendor Code"}
              value={item?.vendorCode}
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label
              labelStyle={componentStyles.label}
              valueStyle={componentStyles.value}
              label={"Vendor Code"}
              value={item?.vendorCode}
            />
          )}
        </Grid>

        <Grid item xs={2}>
          {isEditable ? (
            <RHAutoComplete
              label={"Order Status"}
              options={Object.values(enums["vendor-portal"].orderStatus).filter(
                (status) =>
                  isVendor
                    ? !(
                        status ===
                          enums["vendor-portal"].orderStatus.assigned ||
                        status === enums["vendor-portal"].orderStatus.pending
                      )
                    : true
              )}
              name={`items[${itemIndex}].orderStatus`}
              value={item?.orderStatus || "-"}
              disableClearable
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label
              labelStyle={componentStyles.label}
              valueStyle={componentStyles.value}
              label={"Order Status"}
              value={item?.orderStatus}
            />
          )}
        </Grid>

        <Grid item xs={2}>
          <Label
            labelStyle={componentStyles.label}
            valueStyle={componentStyles.value}
            label={"Design"}
            value={item?.product?.styleNo || "-"}
          />
        </Grid>
        {/*  {isEditable && isAdmin && isCustomized && isDelivered ? (
            <RHAsyncAutoComplete
              apiUrl={productSearchUrl}
              apiBody={{
                distinct: "styleNo",
                customerId,
                ...lockedFilterCriteria,
              }}
              name={`items[${itemIndex}].product`}
              label={"Style No"}
              getOptionLabel={(opt) => opt?.styleNo}
              onBlur={() => {
                setValue(`items[${itemIndex}].issuedStocks`, []);
              }}
              autoSelect={true}
            />
          ) : (
            <Label labelStyle={componentStyles.label} valueStyle={componentStyles.value}label={"Style No"} value={item?.product?.styleNo || "-"} />
          )}
        </Grid>

        {isDelivered || isReturned || isRepair ? (
          <>
            <Grid item xs={2}>
              {isEditable && isAdmin && isDelivered ? (
                <RHAsyncAutoComplete
                  name={`items[${itemIndex}].issuedStocks`}
                  label={"Issued Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  dynamicAPIbody={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  apiUrl={productSearchUrl}
                  apiBody={{
                    styleNo: item.product?.styleNo,
                    customerId,

                    ...{
                      ...lockedFilterCriteria,
                      $or: [
                        { stockStatus: enums.stockStatus.inventory },
                        {
                          "packagingHistory.assignee._id": customerId,
                          stockStatus: enums.stockStatus.approval,
                        },
                      ],
                    },
                  }}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];

                    (val || []).map((prod) => {
                      (item.returnedStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeIndex.push(stockIndex);
                          return true;
                        }
                      });

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].returnedStocks`,
                        (item.returnedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }

                    setValue(`items[${itemIndex}].issuedStocks`, val);
                  }}
                  // style={{ backgroundColor: "white" }}
                />
              ) : (
                <Label
                  label={"Issued Stocks"}
                  value={(item?.issuedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>

            <Grid item xs={2}>
              {isEditable && isAdmin && isRepair ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].repairStocks`}
                  label={"Repair Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={originalItem.issuedStocks || []}
                  onChange={(_, val) => {
                    let removeIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Repair Stocks"}
                  value={(item?.repairStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {isEditable && isAdmin && isReturned ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].returnedStocks`}
                  label={"Returned Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={issuedStocksOptions || []}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Returned Stocks"}
                  value={(item?.returnedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
          </>
        ) : null} */}
      </Grid>

      {/* Breakup </3 */}
      <Dialog
        open={openBreakup}
        onClose={() => setOpenBreakup(false)}
        maxWidth={"md"}
        fullWidth={true}
      >
        <MyDialogTitle onClose={setOpenBreakup}>Price Breakup</MyDialogTitle>
        <MyDialogContent>
          <Grid container spacing={2}>
            {(openBreakup || []).map((prod, prodIndex) => {
              return (
                prod && (
                  <Grid container style={{ padding: "24px" }} key={prodIndex}>
                    <Grid item xs={6}>
                      <Label
                        labelStyle={{ fontWeight: "bold" }}
                        valueStyle={{ fontWeight: "bold" }}
                        label={"SKU"}
                        value={prod.sku || "-"}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {isVendor ? null : (
                        <Label
                          label={"Price"}
                          value={prod.jewelPrice || "-"}
                          labelStyle={{ fontWeight: "bold" }}
                          valueStyle={{ fontWeight: "bold" }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Live Gold Rate"}
                          value={prod.jewelPriceBreakup?.liveGoldPrice || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Live Pure Metal Price"}
                          value={
                            prod.jewelPriceBreakup?.livePureMetalPrice || "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Gold"}
                          value={prod.jewelPriceBreakup?.gold || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Extras"}
                          value={
                            prod.jewelPriceBreakup?.totalExtrasPrice || "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Gold Labor"}
                          value={prod.jewelPriceBreakup?.goldLaborPrice || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"NetWeight * GoldLabor"}
                          value={
                            prod.jewelPriceBreakup?.netWeightGoldLaborPrice ||
                            "-"
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isVendor ? null : (
                        <Label
                          label={"Wastage"}
                          value={prod.jewelPriceBreakup?.appliedWastage || "-"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {isVendor ? null : (
                        <Label
                          label={"Diamonds"}
                          value={
                            (prod.jewelPriceBreakup?.diamonds || [])
                              .map(
                                (d) => `💠. Price: ${d.price}, Rate: ${d.rate}`
                              )
                              ?.join("\n") || "-"
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
        </MyDialogContent>
      </Dialog>

      {/* Customization */}
      <Dialog
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <MyDialogTitle onClose={setOpenDetails}>Customizations</MyDialogTitle>
        <MyDialogContent>
          <Grid
            container
            spacing={3}
            key={itemIndex}
            style={{ padding: "16px" }}
          >
            <Grid
              item
              xs={12}
              container
              style={{ backgroundColor: "lightblack" }}
            >
              <Grid item xs={2}>
                <Label
                  label={"Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.category?.name || "-"}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Sub Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.subCategory?.name}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Collectin Line"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.collectionLine?.name}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Specific Engraving"}
                  value={item?.specificEngraving}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Melting"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.melting}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Screw"}
                  value={item?.screw}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Size"}
                  value={item?.size}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Color"}
                  value={item?.color}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Setting"}
                  value={item.setting}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Gross Weight (g)"}
                  value={item.grossWeight}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Purity"}
                  value={
                    item?.purity?.value
                      ? `${item.purity.value} KT - ${item.purity.percent} %`
                      : ""
                  }
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Metal Type"}
                  value={item.metalType}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={4}>
                <Label
                  label={"Remarks"}
                  value={item?.remarks}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
            </Grid>

            {item.diamondDetails?.length ? (
              <Grid
                item
                xs={12}
                container
                style={{
                  // backgroundColor: "lightblue",
                  padding: "16px",
                }}
              >
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Diamond Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      {diamondColumns.map((col, colIndex) => (
                        <TableCell key={colIndex}>{col.label}</TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {(item.diamondDetails || []).map(
                        (diamond, diamondIndex) => (
                          <TableRow key={diamondIndex}>
                            {diamondColumns.map((col, colIndex) => (
                              <TableCell key={colIndex}>
                                {colIndex === 0
                                  ? diamondIndex + 1
                                  : getNestedObjValue(diamond, col.field)}
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MyDialogContent>
      </Dialog>
    </MainSection>
  );
};

const componentStyles = {
  greenLabel: { color: "black" },
  label: { fontSize: "10px" },
  value: { fontSize: "12px" },
};

const diamondColumns = [
  { label: "Sr No", field: "srNo" },
  { label: "Quantity", field: "diamondQuantity" },
  { label: "Total Carat (cts)", field: "totalCarat" },

  { label: "Shape", field: "shape" },
  { label: "Color", field: "color" },

  { label: "Solitaire Length (mm)", field: "solitaireSize.length" },
  { label: "Solitaire Width (mm)", field: "solitaireSize.width" },

  { label: "Sieve Length (mm)", field: "sieveSize.length" },
  { label: "Sieve Width (mm)", field: "sieveSize.width" },

  { label: "Cut", field: "cut" },
  { label: "Clarity", field: "clarity" },

  { label: "Polish", field: "polish" },
  { label: "Symmetry", field: "symmetry" },
  { label: "Fluorescence Intensity", field: "fluorescenceIntensity" },
  { label: "Grading Lab", field: "gradingLab" },
];

export default ProductItem;
