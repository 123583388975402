import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { handleGlobalUserControl, toTitleCase } from "@ui/Utils/helper";
import { appModule } from "../api";
import DetailContent from "./components/DetailContent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Required")
    .matches(/^[0-9]{10}$/, "Invalid Phone"), // Adjust the regex for your phone format
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
});

const entitySchema = {
  role: enums.roles.vendor,
};

const VendorDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const model = enums.models["vendor-portal"]?.users;

  const params = useParams();

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);
  const [clientUsers, setClientUsers] = useState([]);
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let action = "Created",
        res = {};

      if (values.dataHubAccess) {
        if (!values.vendorCode?.length) {
          return CustomEventEmitter.emit(
            "alert_error",
            "Select Vendor Code to continue..."
          );
        }
      }

      values.firstName = toTitleCase(values.firstName);
      values.lastName = toTitleCase(values.lastName);

      if (!values._id) {
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecord(model, values);

        await handleGlobalUserControl(
          {
            users: [
              {
                _id: res._id,
                firstName: res.firstName,
                lastName: res.lastName,
              },
            ],
          },
          [appModule]
        );
        action = "Updated";
      }

      formMethods.reset(res);

      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " successfully");
    } catch (err) {
      console.log(err);
      let message = err?.response?.data?.message || "Something went wrong!";
      if (message?.match("duplicate")) {
        message = "User exists with phone: " + values.phone;
      }
      CustomEventEmitter.emit("alert_error", message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />

      <DetailContent isEditable={isEditable} />
    </FormProvider>
  );
};

export default VendorDetail;
