import enums from "helpers/enums";
import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import { Grid } from "@material-ui/core/index";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  formatDateToRead,
  isAdmin,
  isVendor,
  userDetails,
  userIdentifier,
} from "@ui/Utils/helper";
import { defaultFilter, entitySchema } from "../Detail";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import Label from "@ui/components/Label";
import RHDatePicker from "@ui/ReactHookFormControls/RHDatePicker";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import RHDocUpload from "@ui/ReactHookFormControls/RHDocUpload";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";

const DetailContent = ({ isEditable, vendorCodes }) => {
  const { control, watch, setValue } = useFormContext();
  const values = watch();
  const pendingRecordExist = (values.items || []).find(
    (item) => item.orderStatus === enums["vendor-portal"].orderStatus.pending
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: `items`,
  });

  const OrderTypeSelector = ({}) => {
    const handleChange = (event) => {
      if (event.target.value === "Custom") {
        setValue(`orderType`, enums.customOrderType.custom);
      } else {
        setValue(`orderType`, enums.customOrderType.online);
      }
    };

    return (
      <RadioGroup value={values.orderType} onChange={handleChange}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              value={enums.customOrderType.custom}
              control={<Radio />}
              label={enums.customOrderType.custom}
              labelPlacement="top"
              disabled={!isEditable || !pendingRecordExist || isVendor}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value={enums.customOrderType.online}
              control={<Radio />}
              label={enums.customOrderType.online}
              labelPlacement="top"
              disabled={!isEditable || !pendingRecordExist || isVendor}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    );
  };

  return (
    <>
      <MainSection
        title="Customize Your Order"
        style={{ marginTop: "24px" }}
        actions={[
          {
            buttonType: "custom",
            hidden: !isEditable || values.code,
            customIcon: (
              <MyHoverIcons
                muiIcon={<AddAlertOutlinedIcon />}
                muiIconHover={<AddAlertIcon />}
                onClick={() => append(entitySchema.items[0])}
                tooltip={"Add Order"}
              />
            ),
          },
        ]}
      >
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{
            backgroundColor: "#dedede",
            marginBottom: "24px",
            border: "2px solid #ccc", // Add a border
            borderRadius: "8px", // Optional: Add border radius for a rounded look
          }}
        >
          <Grid item xs={4}>
            <OrderTypeSelector />
          </Grid>

          <Grid item xs={4}>
            {isEditable && !isVendor ? (
              <RHAutoComplete
                options={Object.values(enums.customOrderPriority)}
                name="orderPriority"
                label="Priority"
                disableClearable
                autoSelect={true}
              />
            ) : (
              <Label
                label="Priority"
                value={values.orderPriority}
                sameLine={true}
              />
            )}
          </Grid>
        </Grid>

        {fields.map((item, itemIndex) => {
          return (
            defaultFilter(item) && (
              <OrderFields
                key={item.id}
                isEditable={isEditable}
                item={item}
                itemIndex={itemIndex}
                remove={remove}
                vendorCodes={vendorCodes}
              />
            )
          );
        })}
      </MainSection>
    </>
  );
};

const OrderFields = ({ isEditable, item, itemIndex, remove, vendorCodes }) => {
  const { setValue, watch } = useFormContext();
  const values = watch();
  const disableFields =
    values.items[itemIndex].orderStatus !==
    enums["vendor-portal"].orderStatus.pending;

  const entityFields = [
    {
      name: `items[${itemIndex}].category`,
      label: "Category",
      fieldType: enums.fieldTypes.asyncDropdown,
      render: () => values.items[itemIndex]?.category?.name,
      apiUrl: enums.models["data-engine"].category + "/search",
      getOptionLabel: (option) => option?.name || "",
      getOptionSelected: (option, value) => option?.name === value?.name,
      readOnly: disableFields,
      autoSelect: true,
    },
    {
      name: `items[${itemIndex}].subCategory`,
      label: "Sub Category",
      fieldType: enums.fieldTypes.asyncDropdown,
      render: () => values.items[itemIndex]?.subCategory?.name,
      apiUrl: enums.models["data-engine"].subCategory + "/search",
      getOptionLabel: (option) => option?.name || "",
      getOptionSelected: (option, value) => option?.name === value?.name,
      readOnly: disableFields,
      autoSelect: true,
    },
    {
      name: `items[${itemIndex}].screw`,
      label: "Screw",
      fieldType: enums.fieldTypes.dropdown,
      options: Object.values(enums.screws),
      render: () => values.items[itemIndex]?.screw,
      readOnly: disableFields,
      autoSelect: true,
    },
    {
      name: `items[${itemIndex}].purity`,
      label: "Purity",
      fieldType: enums.fieldTypes.asyncDropdown,
      render: () =>
        values.items[itemIndex]?.purity?.value
          ? values.items[itemIndex].purity.value + " KT"
          : "",
      apiUrl: enums.models["data-engine"].purity + "/search",
      getOptionLabel: (option) => (option?.value ? option?.value + " KT" : ""),
      getOptionSelected: (option, value) => option?.value === value?.value,
      readOnly: disableFields,
      autoSelect: true,
    },

    {
      name: `items[${itemIndex}].grossWeight`,
      label: "Product Weight",
      fieldType: enums.fieldTypes.textField,
      dataType: enums.dataTypes.number,
      weightField: true,
      render: () => values.items[itemIndex]?.grossWeight,
      readOnly: disableFields,
    },
  ];

  const QuantityUnitSelector = ({}) => {
    const handleChange = (event) => {
      if (event.target.value === "piece") {
        setValue(`items[${itemIndex}].quantityUnit`, {
          piece: true,
        });
      } else {
        setValue(`items[${itemIndex}].quantityUnit`, {
          pair: true,
        });
      }
    };

    return (
      <RadioGroup
        value={values.items[itemIndex]?.quantityUnit?.piece ? "piece" : "pair"}
        onChange={handleChange}
      >
        <Grid
          container
          spacing={2}
          justify="space-between"
          style={{ padding: "8px" }}
        >
          <Grid item xs={2}>
            <FormControlLabel
              value={"piece"}
              control={<Radio />}
              label="Piece"
              labelPlacement="top"
              disabled={disableFields || !isEditable}
            />
          </Grid>
          <Grid item xs={2} style={{ marginRight: "48px" }}>
            <FormControlLabel
              value={"pair"}
              control={<Radio />}
              label="Pair"
              labelPlacement="top"
              disabled={disableFields || !isEditable}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    );
  };

  return (
    <GeneralSection
      // actions={sectionActions}
      fields={entityFields}
      title={`#${itemIndex + 1}`}
      isEditable={isEditable}
      disableFields={disableFields}
      values={values}
      style={{ backgroundColor: itemIndex % 2 === 0 ? "#e6fff6" : "" }}
      actions={[
        {
          buttonType: "custom",
          hidden: !isEditable || disableFields,
          customIcon: (
            <MyHoverIcons
              muiIcon={<RemoveCircleOutlineIcon />}
              muiIconHover={<RemoveCircleIcon />}
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure to dismiss order #${itemIndex + 1}?`
                  )
                ) {
                  remove(itemIndex);
                }
              }}
              tooltip={"Dismiss"}
            />
          ),
        },
      ]}
    >
      <Grid item xs={2}>
        <RHDocUpload
          previewThumbnail={true}
          isEditable={isEditable}
          model={enums.models["e-creatives"].customOrders}
          previewStyle={{ height: 100, width: 100 }}
          name={`items[${itemIndex}].attachments`}
          label={"Attachments"}
          accept={"*"}
          disabled={!isEditable || isVendor}
        />
      </Grid>

      <Grid item xs={8}>
        {isEditable && !disableFields ? (
          <RHTextField
            name={`items[${itemIndex}].description`}
            label="Description"
            multiline={true}
            rows={4}
          />
        ) : (
          <Label
            label="Description"
            value={values.items[itemIndex]?.description || ""}
          />
        )}
      </Grid>

      <Grid item xs={2}>
        {isEditable && !disableFields ? (
          <RHTextField
            name={`items[${itemIndex}].quantity`}
            label="Quantity"
            fieldType={enums.fieldTypes.textField}
            type={enums.dataTypes.number}
          />
        ) : (
          <Label
            label="Quantity"
            value={values.items[itemIndex]?.quantity || ""}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <QuantityUnitSelector />
      </Grid>

      <Grid item xs={3}>
        {isEditable &&
        (isAdmin ||
          userIdentifier.isSalesPerson ||
          userIdentifier.isSalesHead) ? (
          <RHAutoComplete
            name={`items[${itemIndex}].vendorCode`}
            label="# Vendor Code"
            options={vendorCodes}
            autoSelect={true}
          />
        ) : (
          <Label
            label="# Vendor Code"
            value={values.items[itemIndex]?.vendorCode || "-"}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {(isEditable && (isAdmin || userIdentifier.isSalesHead)) ||
        (isEditable && userIdentifier.isSalesPerson && !disableFields) ? (
          <RHDatePicker
            name={`items[${itemIndex}].dueDate`}
            label="Due Date"
            disablePast
          />
        ) : (
          <Label
            label={"Due Date"}
            value={
              item.dueDate
                ? formatDateToRead(values.items[itemIndex]?.dueDate)
                : ""
            }
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {isEditable && !userIdentifier.isSalesPerson ? (
          <RHAutoComplete
            name={`items[${itemIndex}].orderStatus`}
            label="Order Status"
            disableClearable={true}
            options={Object.values(enums["vendor-portal"].orderStatus).filter(
              (status) =>
                isVendor
                  ? status !== enums["vendor-portal"].orderStatus.pending
                  : true
            )}
            onChange={() =>
              setValue(`items[${itemIndex}].dateDelivered`, Date.now())
            }
            autoSelect={true}
          />
        ) : (
          <Label
            label="Order Status"
            value={values.items[itemIndex]?.orderStatus || "-"}
          />
        )}
      </Grid>
      <Grid item xs={3}></Grid>

      <Grid item xs={8}>
        {isEditable &&
        (isAdmin ||
          ((userIdentifier.isSalesPerson || userIdentifier.isSalesHead) &&
            !disableFields)) ? (
          <RHTextField
            name={`items[${itemIndex}].adminRemarks`}
            label="Admin Remarks"
            fieldType={enums.fieldTypes.textField}
            multiline={true}
            rows={4}
          />
        ) : (
          <Label
            label="Admin Remarks"
            value={values.items[itemIndex]?.adminRemarks || ""}
          />
        )}
      </Grid>

      <Grid item xs={4}>
        <Label
          label={"Status Updated Date"}
          value={
            values.items[itemIndex]?.dateDelivered
              ? formatDateToRead(
                  values.items[itemIndex]?.dateDelivered,
                  globalDateTimeFormat.read
                )
              : null
          }
        />
      </Grid>
    </GeneralSection>
  );
};

export default DetailContent;
