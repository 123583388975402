import { PDFDocument, StandardFonts } from "pdf-lib";
import commonEnums from "../commonEnums";

const staticTEMPLATE = {
  one: "Template-1",
  two: "Template-2",
  three: "Template-3",
  four: "Template-4",
};
// Dynamically import templates based on appModule and model
const loadTemplate = async (appModule, model, selectedTemplate) => {
  switch (appModule) {
    case "vendor-portal":
      switch (model) {
        case "quotations":
          switch (selectedTemplate) {
            case staticTEMPLATE.one:
              const { Template1 } = await import(
                `./templates/vendor-portal/quotations/${selectedTemplate}`
              );
              return Template1;

            case staticTEMPLATE.two:
              const { Template2 } = await import(
                `./templates/vendor-portal/quotations/${selectedTemplate}`
              );
              return Template2;

            case staticTEMPLATE.three:
              const { Template3 } = await import(
                `./templates/vendor-portal/quotations/${selectedTemplate}`
              );
              return Template3;

            case staticTEMPLATE.four:
              const { Template4 } = await import(
                `./templates/vendor-portal/quotations/${selectedTemplate}`
              );
              return Template4;
          }
      }
      break;

    case "e-creatives":
      switch (model) {
        case commonEnums.datastores["e-creatives"].models.customOrders:
          const { Template1 } = await import(
            `./templates/e-creatives/customOrders/${selectedTemplate}`
          );
          return Template1;
      }
      break;
  }
};

const generatePDF = async (
  { data, title, selectedTemplate, appModule, model },
  fileName,
  returnPdf = false // Optional parameter to decide behavior
) => {
  try {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 7;
    const pageWidth = 595; // A4 width
    const pageHeight = 842; // A4 height
    const margin = 50;

    // Dynamically load the template
    const templateFunction = await loadTemplate(
      appModule,
      model,
      selectedTemplate
    );

    if (templateFunction) {
      await templateFunction(
        pdfDoc,
        { data, title },
        {
          pageWidth,
          pageHeight,
          font,
          margin,
          fontSize,
        }
      );
    } else {
      console.error("Template not found for the given appModule/model.");
      return;
    }

    // Save the PDF
    const pdfBytes = await pdfDoc.save();

    if (returnPdf) {
      return pdfBytes; // Return the PDF bytes if requested
    } else {
      // Default behavior: Download the PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName || model}.pdf`;
      link.click();
    }
  } catch (err) {
    console.error("Error generating PDF:", err);
    throw err; // Rethrow to propagate errors to the parent function
  }
};

export default generatePDF;
