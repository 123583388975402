import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getByCode, updateRecord } from "../../api";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { isVendor, userDetails } from "@ui/Utils/helper";
import DetailContent from "./components/DetailContent";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {};

const CatalogueQuotationDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models["e-creatives"]?.catalogueQuotations;
  const params = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(
            model,
            id,
            `populate=${JSON.stringify([
              {
                path: "items.product",
                db: "data-engine",
                collection: "products",
              },
            ])}`
          );

          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(false);
        } else {
          navigate("/" + model);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created";

      if (values.code) {
        res = await updateRecord(model, values);
        action = "Updated";
      }

      formMethods.reset(res);
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", action + " Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoading}
        setIsEditable={setIsEditable}
        initialValues={initialValues}
      />

      {initialValues.code && (
        <DetailContent initialValues={initialValues} isEditable={isEditable} />
      )}
    </FormProvider>
  );
};

export const defaultFilter = (item) => {
  if (isVendor) {
    return (userDetails.vendorCode || []).find(
      (vCode) =>
        vCode === item.vendorCode &&
        item.orderStatus && // because quoteIt is offline and still not handled with defaultOrderstatus from realm in mobile app
        item.orderStatus !== enums["vendor-portal"].orderStatus.pending
    );
    // &&
    // (isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    //   : true)
  } else {
    return true;
    // isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    // : ;
  }
};

export default CatalogueQuotationDetail;
