import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApi?.["vendor-portal"];

export const createRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
