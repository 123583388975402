import React from "react";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { Grid } from "@material-ui/core/index";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";

const DetailContent = ({ isEditable }) => {
  return (
    <MainSection
      title={"Access"}
      style={{ marginBottom: "24px", height: "100%" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <RHCheckbox
            disabled={!isEditable}
            name="dataHubAccess"
            label={"Data Hub Screen"}
          />
        </Grid>
      </Grid>
    </MainSection>
  );
};

export default DetailContent;
